import React from 'react';
import {Link} from "react-router-dom";

function Logo() {
  return(
    <svg viewBox="0 0 720 720" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M360.8 720C559.181 720 720 559.181 720 360.8C720 162.419 559.181 1.59998 360.8 1.59998C162.419 1.59998 1.59998 162.419 1.59998 360.8C1.59998 559.181 162.419 720 360.8 720Z" fill="#00bdff"/>
        <path d="M317.2 161.7C305.4 181.3 289.8 208.6 289.8 222C289.8 228.4 294.6 230.1 297.4 230.1C307.1 230.1 330.2 185 331 182.4L333.4 183C329.4 193 309.1 233.1 297.1 233.1C289.2 233.1 285.4 228.6 285.4 222C285.4 208.1 296.2 182.3 306.4 162C303.1 162.3 299.8 162.6 296.5 162.6C295.3 162.6 294.2 162 294.2 161.2C294.2 159.9 301 158.8 308.3 158.2C313.4 148.2 318.2 139.9 320.9 135.9C321.6 134.8 322.4 134.4 323.1 134.4C325.4 134.4 329.2 139.6 329.2 141.1C329.2 141.4 329.1 141.7 328.9 142C327.1 145.3 323.6 150.9 319.5 157.6H320.3C324.5 157.6 334.2 157.5 334.2 160C334.2 161.2 332.7 162.7 330 162.7C329.7 162.7 329.4 162.6 329.1 162.6C325.2 162.2 321.1 161.9 317.2 161.7Z" fill="black"/>
        <path d="M378 108.6C381.1 108.6 383.2 111.1 383.2 113.2C383.2 115 382.6 117.3 381.1 119.6C371.5 134.4 346.5 162.9 327.3 201C336.6 188.7 360.7 165 371.8 165C373.6 165 378.4 167.7 378.4 169.6C378.4 169.9 378.4 170.2 378.1 170.5C368.7 179.8 350.4 211.4 350.4 224.9C350.4 228 351.5 229.8 354.3 229.8C364.1 229.8 387.1 184.7 387.9 182.1L390.3 182.7C386.3 192.7 366 232.8 354 232.8C346.1 232.8 342.3 228.3 342.3 221.7C342.3 206.6 355 185 365.2 171.9V171.8C365.2 171.7 365 171.3 364.7 171.3C357.9 171.3 326.6 202.9 320.2 217C316.2 225.7 315.6 226.1 312.1 234.8C311.9 234.9 311.8 235.1 311.5 235.1C310.5 235.1 307.7 233.3 307.7 232.6C307.8 218.1 372.1 108.6 378 108.6Z" fill="black"/>
        <path d="M376.2 214.4C376.2 192.1 399.1 159.7 414.3 159.7C419.4 159.7 424.8 165.1 424.8 170.9C424.8 184.2 399.3 200 387.5 203.7C385.2 209.1 383.9 214.2 383.9 218.2C383.9 224.3 386.3 230.2 391.2 230.2C407.8 230.2 430.6 184.9 431.4 182.4L433.8 183C429.8 193 409.8 234.1 392.7 234.1C382.5 234.2 376.2 227.9 376.2 214.4ZM388.8 200.3C403 194.6 419.7 177.5 419.7 167.5C419.7 165.2 419.1 164.1 416.9 164.1C411.1 164 396.6 183 388.8 200.3Z" fill="black"/>
        <path d="M360.1 264.6V513.9H290.3V391.4L256.6 462.8L222 389.5V513.8H152.2V264.6H232.3L256.6 315.7L280.9 264.6H360.1Z" fill="black"/>
        <path d="M567.9 388.6C568.2 427.2 557.6 457.8 536.7 479.9C515.8 502.3 487.2 513.6 450.7 513.9C450.1 513.9 449.8 513.9 449.5 513.9H379.7V264.6H449.5C486 264.3 514.6 275.5 535.8 297.6C557 319.7 567.6 350.3 567.9 388.6ZM494.4 388.9C494.4 366.8 490.7 350.3 483.5 339.7C476.3 329.4 465.1 324.1 449.8 324.1C449.5 324.1 449.5 324.1 449.5 324.1V454.3C449.8 454.3 450.1 454.3 450.4 454.3C465.7 454.3 476.9 449 484.1 438.4C491.3 427.8 494.7 411.3 494.4 388.9Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="720" height="720" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

function Home() {
  return(
    <React.Fragment>
      <header id="header">
        <div className="logo"><Logo /></div>
        <nav>
          <ul>
            <li><Link to="/work" activeClassName="active">Work</Link></li>
            <li><Link to="/about" activeClassName="active">About</Link></li>
            <li><Link to="/contact" activeClassName="active">Contact</Link></li>
          </ul>
        </nav>
      </header>
      <footer id="footer">
        <p className="copyright">&copy; Developer Mukhin Dmitry.</p>
      </footer>
    </React.Fragment>
  )
}


export default Home;
